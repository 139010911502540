
.text-slider{
    width: 100%;
    height: 100%;
    overflow: hidden;
    letter-spacing: -0.05em;
    line-height:1.3;
    .swiper-slide {
        text-align: center;
        font-size: 2.2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 10px;
    }
}

.counter{
    position: relative;
    display: flex;
    justify-content: center;
    margin-bottom: 25px;
}
.brackets{
    width: 50px;
    font-size: 3.8rem;
    line-height: 0.45;

    height: auto;
    overflow: hidden;
    position: relative;
    svg{
        fill: $color_primary;
        z-index: 10;
        width: 100%;
        height: auto;
    }
}
.turn{
    z-index: -1;
    position: absolute;
    top: 0;
    left:50%;
    transform: translateX(-50%);

}
.circle{
    left: 50%;
    transform: translateX(-50%);
    transition: transform 0.66s ease-in-out;
    top:20px;
    width: 200px;
    height: 200px;
    position: relative;
    display: flex;
    justify-content: center;
    .nr{
        color: $color_primary;
        position: absolute;
        height: 50%;
        transform-origin: center bottom;
        span{
            display: block;
            transform: scaleX(0.88);
        }
    }
}


@media ( min-width: $phone ) {
    .brackets{
        width: 60px;
        font-size: 4.6rem;
        line-height: 0.55;
    }
    .text-slider .swiper-slide{
        font-size: 3rem;
    }
}
@media ( min-width: $tablet_portrait ) {
    .brackets{
        width: 70px;
        font-size: 5.6rem;
        line-height: 0.7;
    }
    .text-slider .swiper-slide{
        font-size: 4rem;
        padding: 0 35px;
    }
}
@media ( min-width: $tablet_landscape ) {
    .brackets{
        width: 76px;
        font-size: 6.1rem;
        line-height: 0.75;
    }

    .text-slider .swiper-slide{
        font-size: 4.5rem;
    }
}
@media ( min-width: $desktop ) {
    .brackets{
        width: 86px;
        font-size: 6.9rem;
        line-height: 0.8;
    }
    .text-slider .swiper-slide{
        font-size: 5rem;
    }
}

@media only screen and (min-width: $tablet_portrait) and (max-height: $tablet_portrait) { 
    .brackets{
        width: 70px;
        font-size: 5.6rem;
        line-height: 0.7;
    }
    .text-slider .swiper-slide{
        font-size: 4rem;
    }
}
@media only screen and (min-width: $phone) and (max-height: $phone) { 
    .brackets{
        width: 60px;
        font-size: 4.6rem;
        line-height: 0.55;
    }
    .text-slider .swiper-slide{
        font-size: 3rem;
    }
}
.header{
    padding-top: 25px;
    z-index: 999;
}
.header__logo{
    width: 130px;
    height: auto;
    left: 50%;
    transform: translateX(-50% );
    position: absolute;
    color: $color_primary;
    z-index: 999;
    svg{
        width: 100%;
        height: auto;
        fill: currentColor;
    }
    .group{
        overflow: hidden;
        position: relative;
    }
    .group__inner{
        position: absolute;
    }
    .bg--secondary & {
        color: $color_primary;
    }
    .bg--primary & {
        color: $color_secondary;
    }
}
.order-btn,
#main-menu__btn{
    z-index: 10;
    line-height: 1;
    transform: translateY(-0.1em);
}
.order-btn{
    position: fixed;
    right: 30px;
    overflow: hidden;
    line-height: 1;
}

#main-menu__btn{
    overflow: hidden;
    position: fixed;
    left: 30px;
    color: $white;
    display: block;
    &:hover{
        .bg--secondary & {
            color: $color_primary;
        }
        .bg--primary & {
            color: $color_secondary;
        }
    }
}

.main-menu{
    z-index: 99;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    transform: translateY(100%);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 3.6rem;
    .bg--primary &{
        background-color: $color_secondary;
        .main-menu__link{
            &:hover,
            &:active,
            &.is-active{
                color: $color_primary;
            }
        }
    }
    .bg--secondary &{
        background-color: $color_primary;
        .main-menu__link{
            &:hover,
            &:active,
            &.is-active{
                color: $color_secondary;
            }
        }
    }
}
.main-menu__item{
    margin: 0.4em 0;
}
.main-menu__link{
    display: block;
    padding: 0.1em 0.5em;
}

@media (min-width: $phone) and (min-height: 660px) {
    .header__logo{
        width: 160px;
    }
}
@media (min-width: $tablet_landscape) and (min-height: 660px) {
    .header__logo{
        width: 230px;
    }
}

.font--display{
    font-family: 'DrukText', sans-serif;
    // font-weight: 600;
    text-transform: uppercase;
}
.font-size-nav{
    font-size: 1.5rem;
}
@media (min-width: $phone_s) {
    .font-size-nav{
        font-size: 2rem;
    }
}
@media (min-width: $phone) {
    .font-size-nav{
        font-size: 2.6rem;
    }
}
@media (min-width: $tablet_landscape) {
    .font-size-nav{
        font-size: 3rem;
    }
}
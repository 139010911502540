*, *:before, *:after {
    box-sizing: border-box;
    margin: 0;
}
:focus {
	outline: 0;
}
ul, ol{
    padding: 0;
    list-style: none;
}
img, video{
    display: block;
    max-width: 100%;
}
svg{
    display: block;
}
h1, h2, h3, h4, h5{
    font-size: 100%;
    margin: 0;
}
p{
    margin: 0;
}
a{
    text-decoration: none;
    color: currentColor;
    &:visited,
    &:focus,
    &:active{
        text-decoration: none;
        color: currentColor;
    }
    &:visited,
    &:focus,
    &:active,
    &.is-active{
        text-shadow: none!important;
    }
}
button{
    display: inline-block;
    border: none;
    padding: 0;
    margin: 0;
    text-decoration: none;
    background: transparent;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
}
iframe{
    margin: none;
    padding: none;
    background: blue; /* this is just to make the frames easier to see */
    border: none;
}

.image-slider{
    width: 100%;
    height: calc(100vh - 230px);
    margin-top: 30px;
    overflow: hidden;
    transition: 0.2s ease;
    opacity: 0;
    display: flex;
    align-items: center;
    &.loaded{
        opacity: 1;
    }
    .pre{
        filter: blur(4px);
    }
}
@media ( max-width: $tablet_portrait ) {
    .image-slider--desktop{
        display: none!important;
    }
    .image-container{
        position: relative;
        height: 100%;
        img{
            object-fit: cover;
            width: 100%;
            height: 100%;
            &.main{
                opacity: 0;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                right: 0;
                bottom: 0;
                transition: 0.23s ease;
                &.swiper-lazy-loaded{
                    opacity: 1;
                }
            }
        }
    }
}


@media ( min-width: $tablet_portrait ) {
    .image-slider--mobile{
        display: none!important;
    }
    .image-slider{
        width: 100%;
        height: 100%;
        max-height: calc(100vh - 230px);
    }
    .image-container{
        position: relative;
        img{
            width: 100%;
            &.main{
                opacity: 0;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                right: 0;
                bottom: 0;
                transition: 0.23s ease;
                &.swiper-lazy-loaded{
                    opacity: 1;
                }
            }
        }
    }
    .double-image{
        display: flex;
        .image-container{
            width: 50%;
        }
    }
}


@media (min-width: $phone) and (min-height: 660px) {
    .image-slider{
        max-height: calc(100vh - 250px);
        margin-top: 40px;
    }
}
@media (min-width: $tablet_landscape) and (min-height: 660px) {
    .image-slider{
        max-height: calc(100vh - 260px);
        margin-top: 120px;
    }
}
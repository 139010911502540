.swiper-wrapper{
    align-items: center;
}
.swiper-slide{
    width: 100%;
}

.slider-arrow{
    position: absolute;
    z-index: 99;
    padding: 4px;
    cursor: pointer;
    width: 26px;
    top: auto;
    bottom: -40px;

    svg{ 
        width: 100%;
        height: auto;
        fill: white; 
    }
    transition: opacity 0.23s ease;
    &.swiper-button-disabled{
        opacity: 0.2;
        pointer-events: none;
    }
}
@media ( min-width: $tablet_portrait ) {
    .slider-arrow--mobile{
        display: none!important;
    }
}
@media ( max-width: $tablet_portrait ) {
    .slider-arrow--desktop{
        display: none!important;
    }
}
.slider-arrow.to-prev{
    svg{ transform: scaleX(-1) }
    left: 30px;
}
.slider-arrow.to-next{
    right: 30px;
}

body.bg--secondary{
    .slider-arrow{
        &:hover{
            svg{ fill: $color_primary }
        }
    }
}
body.bg--primary{
    .slider-arrow{
        &:hover{
            svg{ fill: $color_secondary}
        }
    }
}

@import 'layout/image-slider.scss';
@import 'layout/text-slider.scss';


@media ( min-width: $tablet_landscape ) {
    .slider-arrow{
        transform: translateY(-50%);
        bottom: auto;
        top: 50%;
        width: 30px;
    }
    .slider-arrow.to-prev{
        left: 20px;
    }
    .slider-arrow.to-next{
        right: 20px;
    }
}
@media ( min-width: 1460px ) {
    .slider-arrow.to-prev{
        left: calc( 4vw );
    }
    .slider-arrow.to-next{
        right: calc( 4vw );
    }
}
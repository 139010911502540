
.template--contact{
    font-size: 2.6rem;
    .columns{
        margin: 0 -10px;
        letter-spacing: -0.02em;
        .col{
            width: 100%;
            padding: 0 10px;
            margin: 10px 0;
            text-align: center;
        }
    }
    .social{
        margin: 0 -10px;
        a{
            padding: 0 10px;
        }
    }
} 
@media (min-width: $phone_s) {
    .template--contact{
        font-size: 3rem;
    }
}
@media (min-width: $phone) {
    .template--contact{
        font-size: 3.4rem;
    }
}
@media (min-width: $tablet_portrait) {
    .template--contact{
        font-size: 3.8rem;
    }
}
@media (min-width: $tablet_landscape) {
    .template--contact{
        
        .columns{
            .col{
                width: 50%;
                &:nth-of-type(1){ text-align: left; }
                &:nth-of-type(2){ text-align: right; }
            }
        }
    }
}
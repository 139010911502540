
// Typography
// ---------------------

$displayFont: 'DrukText';

// Colors
// ---------------------

$white: #fff;
$black: #000;

$color_primary: #990800;
$color_secondary: #11183d;


// Breakpoints
// ---------------------

$phone_s: 460px;
$phone: 580px;
$tablet_portrait: 768px;
$tablet_landscape: 1024px;
$desktop: 1280px;
html { font-size: 62.5%; } 
body {
    position: relative;
    min-height: 100vh;
    font-family: 'DrukText', 'Helvetica', sans-serif;
    font-size: 1.6rem;
    line-height: 1.33;
    -webkit-font-smoothing: antialiased;
    color: $white;
    background-color: $color_secondary;
    &.bg--secondary{
        background-color: $color_secondary;
    }
    &.bg--primary{
        background-color: $color_primary;
    }
    transition:  background-color 0.23s ease;
}
::-moz-selection { /* Code for Firefox */
    color: $white; 
    background: $color_primary; 
}
::selection {
    color: $white; 
    background: $color_primary; 
}
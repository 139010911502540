@import 'layout/header.scss';
@import 'layout/bottom-menu.scss';
@import 'layout/sliders.scss';
@import 'layout/contact.scss';


.bg--secondary{
    a, button{
        &:hover,
        &:active,
        &.is-active{
            color: $color_primary;
        }
    }
}
.bg--primary{
    a, button{
        &:hover,
        &:active,
        &.is-active{
            color: $color_secondary;
        }
    }
}
.site-main{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    z-index: 10;
}

.highway-router-view{
    width: 100%;
}


.site-background{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;
}
.img-cover{
    width: 100%;
    height: 100%;
    object-fit: cover;
    font-family: 'object-fit: cover;';
}

.container{
    width: 100%;
    max-width: 1370px;   
    padding: 0 30px;
    margin: 0 auto;
}
.columns{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    .col{
        width: 50%;
    }
}
@media ( min-width: $tablet_landscape ) {
    .container{
        padding: 0 70px;
    }
}

@media ( min-width: $desktop ) {
    max-width: 1280px;  
    .container{
        padding: 0 110px;
    }
}
// .u-text--right{
//     text-align: right;
// }
.u-text-center{
    text-align: center;
}
.u-text-shadow{
    text-shadow: 1px 1px 1px rgba(black, 0.8);
}
.u-italic{
    font-style: italic;
}
@font-face {
    font-family: 'DrukText';
    src:    url('/assets/fonts/druktext-medium.woff2') format('woff2'),
            url('/assets/fonts/druktext-medium.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'DrukText';
    src:    url('/assets/fonts/druktext-mediumitalic.woff2') format('woff2'),
            url('/assets/fonts/druktext-mediumitalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
}
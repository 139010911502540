.bottom-menu{
    // opacity: 0;
    z-index: 20;
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    right: 0;
}
.bottom-menu__list{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px 20px;
}
.bottom-menu__item{
    overflow: hidden;
}
.bottom-menu__link{
    display: block;
}